var exports = {};

exports = string => string.replace(/^[\r\n]+/, "").replace(/[\r\n]+$/, "");

exports.start = string => string.replace(/^[\r\n]+/, "");

exports.end = string => {
  let end = string.length;

  while (end > 0 && (string[end - 1] === "\r" || string[end - 1] === "\n")) {
    end--;
  }

  return end < string.length ? string.slice(0, end) : string;
};

export default exports;
export const start = exports.start,
      end = exports.end;